<template>
  <MainLayout :title="title" v-on:logout="$emit('logout')">
    <div class="mt-5" v-html="$mystore.state.agenda"></div>
  </MainLayout>
</template>

<script>
import MainLayout from '@/components/layouts/Main.vue'
import handle_errors from '@/api/handle_errors.js'
import catch_errors from '@/api/catch_errors.js'
import handle_token_refresh from '@/api/handle_token_refresh.js'

async function getAgenda(token) {
  return await fetch(`${process.env.VUE_APP_API_URL}/voter/agenda`, {
    credentials: 'include',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export default {
  name: 'Agenda',

  components: {
    MainLayout,
  },

  data() {
    return {
      title: this.$t('views.agenda.title'),
    }
  },

  watch: {
    '$i18n.locale': function () {
      this.title = this.$t('views.agenda.title')
      document.title = this.title
    },
  },

  created: function () {
    document.title = this.title

    getAgenda(localStorage.getItem('token'))
      .then(handle_token_refresh)
      .then(handle_errors)
      .then(data => {
        this.$mystore.setAgenda(data.data.agenda)
      })
      .catch(response => {
        catch_errors(response, this.$router, this.$mystore)
      })
  },
}
</script>
